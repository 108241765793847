import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import Image from "../image"
import Typography from "@material-ui/core/Typography"
import Hero from '../hero';
import Button from "@material-ui/core/Button"
import { Box } from '@material-ui/core';
import { BookAnAppointmentForm } from '../bookanappointment/BookAnAppointmentForm';
import CloseIcon from '@mui/icons-material/Close';
import { Modal } from "@mui/material";

function SlideShow(props) {
    var items = [
        {
            name: "Random Name #1",
            description: "Kerala’s biggest campus in the Special Education and Therapy field",
            image: "BANNER 7.jpg"
        },
        {
            name: "Random Name #2",
            description: "Not- for- profit institution run by The Mar Thoma Church  ",
            image: "BANNER 1.jpg"
        },
        {
            name: "Random Name #2",
            description: "Free education for children with special abilities",
            image: "BANNER 3.jpg"
        },
        {
            name: "Random Name #2",
            description: "Therapeutic services available at subsidized rates",
            image: "BANNER 4.jpg"
        },
        {
            name: "Random Name #2",
            description: "Highly educated and passionate staff",
            image: "BANNER 5.jpg"
        },
        {
            name: "Random Name #2",
            description: "Top class therapy facilities and modern equipments ",
            image: "BANNER 6.jpg"
        },
        {
            name: "Random Name #2",
            description: "Continued support provided to trainees who successfully complete vocational training ",
            image: "BANNER 2.png"
        }
    ]

    const handleAppointmentClick = () => {
        handleOpen();
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80vw",
        bgcolor: 'background.paper',
        p: 4,
        marginTop: 50,
        marginBottom: 50
    };

    return (
        <div style={{ alignItems: "center", textAlign: "center" }}>

            <Carousel navButtonsAlwaysVisible animation='fade'>
                {
                    items.map((item, i) => <div>
                        <Item key={i} item={item} />
                        <Typography className="body-font" align="center" variant="body1" style={{ width: 'auto', color: "#154b68", marginBottom: 5, paddingLeft: 10, paddingRight: 10 }} >
                            {item.description}
                        </Typography>
                    </div>)
                }
            </Carousel>

            <Button variant="contained" color="secondary" size="large"
                onClick={handleAppointmentClick}
                style={{
                    margin: 20,
                    marginBottom: 0,
                }}>
                Book an appointment
            </Button>

            <Hero />
            <Modal style={{ outline: 'none', height: '90vh', margin: 50, overflowY: 'scroll', overflowX: 'hidden' }}
                sx={{ margin: { xs: "10px !important", sm: "50" } }}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <BookAnAppointmentForm handleModalClose={handleClose} />
                </Box>
            </Modal>

        </div>
    )
}

function Item(props) {
    return (
        <div style={{ width: "100%", paddingTop: 10 }} className="image-style"  >

            <Paper style={{ boxShadow: "none", background: "transparent", alignItems: "center", textAlign: "center" }}>
                <div style={{ width: 1336, height: 500 }} className='slideshow-image'>
                    <Image
                        alt="JYOTHIS SPECIAL SCHOOL"
                        filename={props.item.image}
                    />
                </div>

            </Paper>
        </div>

    )
}

export default SlideShow