import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Image from "../../../components/image"

interface IProps {
  management: Management
}

interface Management {
  title: string
  image: string
  description: string
}

const AppointmentCard = ({ management, ...props }: IProps) => {
  return (
    <Card style={{ display: "inline-block", minHeight: 300, background: "transparent", margin: "0 20", boxShadow: "none" }}>
      <CardContent style={{ textAlign: "center", minHeight: 250, width: 300, color: "#154b68", }}>
        <div style={{ padding: 0, minHeight: 400 }}>
          <Image alt={management.title} filename={management.image} />
        </div>
        <Typography variant="h6" color="inherit">
          {management.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {management.description}
        </Typography>
      </CardContent>
    </Card>
  )
}
export default AppointmentCard
