import React from "react"
import Grid from "@material-ui/core/Grid"
import AppointmentCard from "./components/appointment-card"
import { Box } from "@material-ui/core"
import Offers1 from "../offers1/offers1"

const Appointment = () => {
    let management = [
        {
            id: 1,
            image: "bishpos (2).jpg",
            title: "Patron",
            description:
                "Most. Rev. Dr. Theodosius Marthoma Metropolitan",
        },
        {
            id: 3,
            image: "Founder.jpg",
            title: "Founder & President",
            description:
                "Rt. Rev. Dr. Euyakim Mar Coorilos Suffragan Metropolitan",
        },
        {
            id: 4,
            image: "VinodEasow.jpg",
            title: "Director",
            description:
                "Rev. Vinod Easow",
        }
    ]

    return (
        <Grid
            style={{
                textAlign: "center",
                minHeight: 200,
                marginTop: 20
            }}
        >
            <Offers1 />

            <Box
                style={{
                    textAlign: "center",
                    minHeight: 200,
                    padding: 0,
                    margin: 0,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    flexWrap: "wrap"
                }}
            >
                {management.map(x => (
                    <Box key={x.id} >
                        <AppointmentCard management={x} />
                    </Box>
                ))}
            </Box>
        </Grid>
    )
}

export default Appointment
