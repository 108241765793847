import { useState } from "react";
import { IMail } from "../../services/email-service";
import EmailService from "../../services/email-service";

const PostContactForm = async (
    values: any,
    successCallback: any,
    errorCallback: any
) => {
    if (true) successCallback();
    else errorCallback();
};

const initialFormValues = {
    fullName: "",
    email: "",
    service: "",
    date: "",
    question: "",
    formSubmitted: false,
    success: false,
    phone: "",
    whatsapp: "",
    emailSent: false
};

export const useFormControls = () => {
    const [values, setValues] = useState(initialFormValues);
    const [errors, setErrors] = useState({} as any);

    const validate: any = (fieldValues = values) => {
        let temp: any = { ...errors };

        if ("fullName" in fieldValues)
            temp.fullName = fieldValues.fullName ? "" : "This field is required.";

        if ("phone" in fieldValues)
            temp.phone = fieldValues.phone ? "" : "This field is required.";

        if ("email" in fieldValues) {
            if (!fieldValues.email) {
                temp.email = ""
            }

            if (fieldValues.email && fieldValues.email != '')
                temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
                    ? ""
                    : "Email is not valid.";
        }

        if ("date" in fieldValues) {
            var today = new Date();
            today.setHours(0, 0, 0, 0);
            temp.date = fieldValues.date && (fieldValues.date !== "yyyy-mm-dd") ? "" : "This field is required.";
            if (fieldValues.date && (new Date(fieldValues.date) < today)) {
                temp.date = "Date should not be past.";
            }

        }

        if ("service" in fieldValues)
            temp.service = fieldValues.service ? "" : "This field is required.";


        setErrors({
            ...temp
        });
    };

    const handleInputValue = (e: any) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
        validate({ [name]: value });
    };

    const handleSuccess = () => {
        setValues({
            ...initialFormValues,
            formSubmitted: true,
            success: true
        });

        let service: string = '';
        switch (values.service?.toString()) {
            case "10":
                service = 'Special Education';
                break;
            case "20":
                service = 'Vocational Training';
                break;
            case "30":
                service = 'Speech therapy';
                break;
            case "40":
                service = 'Physiotherapy';
                break;
            case "50":
                service = 'Occupational Therapy';
                break;
            default:
                console.log("No service selected");
                break;
        }

        var messageToSend = {
            "customerDetail": {
                "name": values.fullName,
                "contactPhone": values.phone,
                "whatsapp": values.whatsapp,
                "email": values.email
            },
            "requestInfo":[
                {"data": "Service Required", "value" : service},
                {"data": "Preferred Date", "value" : values.date}
            ],
            "referral":values.question,
            "Sender_Name":"Jyothis Special School and Therapy Centre",
            "Sender_Address": "Puthiyakavu P.O",
            "Sender_City": "Mavelikkara, Alappuzha",
            "Sender_State": "Kerala",
            "Sender_Zip": "690101"
        };

        const data:IMail = {
            message: messageToSend
        }
        
        EmailService.sendEmail(data)
            .then(response => {
                setValues({
                    ...initialFormValues,
                    emailSent: true,
                });
            }
            )
            .catch(error => {
                setValues({
                    ...initialFormValues,
                    emailSent: false,
                });
            });
    };

    const handleError = () => {
        setValues({
            ...initialFormValues,
            formSubmitted: true,
            success: false
        });
    };

    const formIsValid = (fieldValues = values) => {
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        const isValid =
            fieldValues.fullName &&
            fieldValues.phone &&
            fieldValues.date && (fieldValues.date !== "yyyy-mm-dd") && (new Date(fieldValues.date) > today)
        Object.values(errors).every((x) => x === "");

        return isValid;
    };

    const handleFormSubmit = async (e: any) => {
        e.preventDefault();
        const isValid =
            Object.values(errors).every((x) => x === "") && formIsValid();
        if (isValid) {
            await PostContactForm(values, handleSuccess, handleError);
        }
    };

    return {
        values,
        errors,
        handleInputValue,
        handleFormSubmit,
        formIsValid,
    };
};
