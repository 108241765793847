import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import SlideShow from "../components/home/slide-show"
import Appointment from "../components/home/appointment"

// markup
const IndexPage = () => {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Layout>
          <SEO title="Home" />
          <SlideShow></SlideShow>
          <Appointment/>
        </Layout>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default IndexPage
