import { Button, } from "@material-ui/core";
import React, { useEffect } from "react";
import { useFormControls } from "./BookAnAppointmentFormControls";
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Image from "../image"
import Hidden from "@material-ui/core/Hidden"
import Bar from "../bar"
import TextField from '@mui/material/TextField';
import { IconButton, Snackbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const inputFieldValues = [
    {
        index: 1,
        name: "fullName",
        label: "Name *",
        id: "fullName"
    },
    {
        index: 2,
        name: "service",
        label: "Service required *",
        id: "service"
    },
    {
        index: 3,
        name: "date",
        label: "Preferred Date *",
        id: "date"
    },
    {
        index: 4,
        name: "phone",
        label: "Contact Phone *",
        id: "phone"
    },
    {
        index: 5,
        name: "whatsapp",
        label: "Whatsapp Number",
        id: "whatsapp"
    },
    {
        index: 6,
        name: "email",
        label: "Email",
        id: "email"
    },
    {
        index: 7,
        name: "question",
        label: "How did you come to know about us",
        id: "question"
    },
];

export const BookAnAppointmentForm = ({handleModalClose}) => {
    const {
        handleInputValue,
        handleFormSubmit,
        formIsValid,
        errors,
        values
    } = useFormControls();

    const [open, setOpen] = React.useState(false);

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        setOpen(values.emailSent);
    }, [values.emailSent]);

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Grid container spacing={3} style={{ paddingBottom: 20, paddingTop: 50 }} alignItems="center" direction="column">
                <Typography style={{ display: "block" }} variant="h5">Book an appointment</Typography>
                <Bar />
            </Grid>
            <Grid container spacing={3} style={{ minHeight: 500 }} alignItems="center" justifyContent="center">
                <Hidden xsDown>
                    <Grid
                        item={true}
                        sm={6}
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        style={{ padding: 10 }}
                    >
                        <div style={{ width: "80%" }}>
                            <Image
                                alt="apppointment.webp"
                                filename="apppointment.webp"
                            />
                        </div>
                    </Grid>
                </Hidden>
                <Grid
                    item={true}
                    xs={12}
                    sm={6}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ paddingLeft: 40, paddingRight: 40 }}
                >
                    <form autoComplete="off" onSubmit={handleFormSubmit} >
                        {inputFieldValues.map((inputFieldValue, index) => {

                            if (inputFieldValue.name === "service") {
                                return (
                                    <FormControl style={{ width: "100%" }} key={index}>
                                        <InputLabel id="demo-simple-select-label">Service required</InputLabel>
                                        <Select
                                            // labelId="demo-simple-select-label"
                                            // id="demo-simple-select"
                                            // value={service}
                                            onChange={handleInputValue}
                                            style={{ marginLeft: 8, marginTop: 5 }}
                                            variant="outlined"
                                            // key={inputFieldValue.index}
                                            fullWidth
                                            onBlur={handleInputValue}
                                            name={inputFieldValue.name}
                                            label={inputFieldValue.label}
                                            error={!!errors[inputFieldValue.name]}
                                            autoComplete="none"
                                            {...(errors[inputFieldValue.name] && {
                                                error: true,
                                                helperText: errors[inputFieldValue.name]
                                            })}
                                        >
                                            <MenuItem key={10} value={10}>Special Education</MenuItem>
                                            <br/>
                                            <MenuItem key={20} value={20}>Vocational Training</MenuItem>
                                            <br/>
                                            <MenuItem key={30} value={30}>Speech therapy</MenuItem>
                                            <br/>
                                            <MenuItem key={40} value={40}>Physiotherapy</MenuItem>
                                            <br/>
                                            <MenuItem key={50} value={50}>Occupational Therapy</MenuItem>
                                        </Select>
                                    </FormControl>
                                )
                            }

                            if (inputFieldValue.name === "date") {
                                return (
                                    <TextField
                                        type="date"
                                        defaultValue={Date.now}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                        style={{ padding: 8, marginTop: 5 }}
                                        key={index}
                                        onChange={handleInputValue}
                                        onBlur={handleInputValue}
                                        name={inputFieldValue.name}
                                        label={inputFieldValue.label}
                                        error={!!errors[inputFieldValue.name]}
                                        fullWidth
                                        autoComplete="none"
                                        {...(errors[inputFieldValue.name] && {
                                            error: true,
                                            helperText: errors[inputFieldValue.name]
                                        })}
                                    />
                                )
                            }

                            return (
                                <TextField
                                    variant="outlined"
                                    style={{ padding: 8, marginTop: 5 }}
                                    key={index}
                                    onChange={handleInputValue}
                                    onBlur={handleInputValue}
                                    name={inputFieldValue.name}
                                    label={inputFieldValue.label}
                                    error={!!errors[inputFieldValue.name]}
                                    fullWidth
                                    autoComplete="none"
                                    {...(errors[inputFieldValue.name] && {
                                        error: true,
                                        helperText: errors[inputFieldValue.name]
                                    })}
                                />
                            );
                        })}
                        <Button
                            variant="contained"
                            type="submit"
                            color="secondary"
                            disabled={!formIsValid()}
                            style={{ marginTop: 10 }}
                        >
                            Send Message
                        </Button>
                        <Button
                            variant="contained"
                            type="submit"
                            color="secondary"
                            onClick={handleModalClose}
                            style={{ marginTop: 10, marginLeft: 10, }}
                        >
                            Close
                        </Button>
                    </form>
                </Grid>
            </Grid>

            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="
                Thank you for choosing Jyothis! We are so grateful for the pleasure of serving you and hope we met your expectations.

Jyothis staff will contact you within the next 24-48 hours to assist you further. Please feel free to contact us at 0479- 2305400 OR 0479- 2306020 for immediate support
"
                action={action}
            />

        </div >
    );
};
