import React from "react"
import Typography from "@material-ui/core/Typography"

const Hero = () => {
  return (
    <Typography className="body-font" align="center" variant="body1" style={{ color: "#154b68", paddingTop: 30, paddingLeft: 10, paddingRight: 10 }} >
      Jyothis Special School and Therapy Centre is an institution founded in 1994, to bridge the gaps in the developmental history of the children. We are proud to have the state’s biggest campus in the Special Education and Therapy field with a 25661 sq. ft. building, well equipped therapy centers, trained and passionate staff, parking area, children’s park etc. Though the institution is in the private management sector, we provide free education and therapeutic services like Speech therapy, Physiotherapy and Occupational therapy at subsidized rates. Our therapy centre is also open to all needy children among the general public.
    </Typography>

  )
}
export default Hero
