import React from 'react';
import "./offers1.css"
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import Bar from '../bar';
import { navigate } from "gatsby"

const Offers1 = () => {

    const handleRedirect = (title: string) => {
        navigate(`/departments#${title}`);
    };

    let services = [
        {
          id: 1,
          image: "search-doctor-icon.png",
          title: "Special Education",
          description:
            "Special school admission is provided for children who are below 18 years of age and have 40% or more disability. We follow the curriculum provided by National Institute of Mentally Handicapped, Hyderabad. The children appear for public equivalency exams after completing their preliminary class, 4th standard, 7th standard and 10th standard. Our special educators are Rehabilitation Council of India (RCI) registered and specialized in the field of special education.",
    
          icon: "fas fa-book-reader"
        },
        {
          id: 2,
          image: "online-pharmacy-icon.png",
          title: "Vocational training",
          description:
            "Vocational training is an instructional program that prepares special children for an occupation. We provide vocational training program for special children above 18 years and help them acquire vocational skills which enhance their employment opportunities and subsequently help them to lead an independent life.",
          icon: "fas fa-puzzle-piece"
        },
        {
          id: 3,
          image: "consultation-icon.png",
          title: "Speech Therapy and Audiology",
          description:
            "Audiologists and Speech language pathologists deal with all the speech and language deficits, dysphagia and also assess the hearing threshold of the clients. Our clinicians provide highly effective therapy sessions for children with intellectual disability, Autism spectrum disorder, cerebral palsy, Down’s syndrome, Learning disability, Speech sound disorder, Attention Deficit Hyperactivity Disorder and Fluency disorders. Audiological evaluation is also done for those who have hearing concerns.",
          icon: "fas fa-comments"
        },
        {
          id: 4,
          image: "details-info-icon.png",
          title: "Physiotherapy",
          description:
            "There are many conditions that require children to seek the help of a physiotherapist. Our qualified and experienced physiotherapists deal with all movement disorders, torticollis, posture issues, muscle tone issues, co-ordination issues, toe walking and other orthopedic conditions.",
          icon: "fas fa-dumbbell"
        },
        {
          id: 5,
          image: "emergency-care-icon.png",
          title: "Occupational Therapy",
          description:
            "Occupational therapist helps people of all ages who have physical, sensory or cognitive problems. They also treat children with Autism spectrum disorder, Attention Deficit Hyperactivity disorder (ADHD), Down syndrome etc.",
          icon: "fas fa-briefcase-medical"
        }
    ]

    return (
        <div className='main-container'>
            <Grid container spacing={3} style={{ paddingBottom: 50, paddingTop: 50 }} alignItems="center" direction="column">
                <Typography style={{ display: "block" }} variant="h5">Our Services:</Typography>
                <Bar />
            </Grid>

            <div className="offer-container body-font">
                <div className="offer-row">
                    {services.map(service => (
                        <div className="offer-service" onClick={(event) => handleRedirect(service.title)}>
                            <i className={service.icon}></i>
                            <h2>{service.title}</h2>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Offers1